<template>
<div>
  <custom-snackbar ref="snackbar" />
  <ActionsComponent
      :filtros="filtros"
      relatorio-excel="HistoricoEstoque"
      excel-pdf
      local-storage-save-name="historico-estoque"
  />
  <FilterComponent v-model="filtros"/>
  <ListComponent :filtros="filtros" :headers="headers" :reload-data.sync="reloadData"/>
</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import ActionsComponent from './components/ActionsComponent.vue'
import FilterComponent from './components/FilterComponent.vue'
import ListComponent from './components/ListComponent.vue'

export default {
  components: { ActionsComponent, FilterComponent, ListComponent },
  data: (instance) => ({
    reloadData: false,
    filtros: {
      empresaId: instance.filtroValue(instance.$route.query.empresaId, '', 'int'),
      producaoId: instance.filtroValue(instance.$route.query.producaoId, '', 'int'),
      produtoId: instance.filtroValue(instance.$route.query.produtoId, '', 'string'),
      data: instance.filtroValue(instance.$route.query.data, instance.$date.getDateNowIso(), 'date'),
    },
    headers: [
      { value: 'data-table-expand', align: 'right p-0', class: 'sticky-header custom-table--header', total: 'title' },
      { text: 'Codigo Estoque', value: 'id', class: 'custom-table--header', obrigatorio: true },
      { text: 'Empresa', value: 'empresa', class: 'sticky-header custom-table--header', obrigatorio: true },
      { text: 'Produção', value: 'producao', class: 'sticky-header custom-table--header', obrigatorio: true },
      { text: 'Produto', value: 'produto', class: 'custom-table--header', obrigatorio: true },
      { text: 'Quantidade', value: 'quantidade', align: 'right', class: 'custom-table--header', obrigatorio: true },
    ],
  }),
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agricola', route: 'agricola' },
      { title: 'Histórico de Estoque' },
    ])
  },
  methods: {
    filtroValue (value, valorPadrao, type) {
      if (value === undefined || value === null || value === '') {
        return valorPadrao
      }

      if (type === 'int') {
        return parseInt(value)
      }

      if (type === 'float') {
        return parseFloat(value)
      }

      if (type === 'array') {
        return [].concat(value)
      }

      return value
    },
  },
}
</script>

<style scoped>

</style>
