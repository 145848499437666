<template>
  <v-container>
    <custom-snackbar
        ref="snackbar"
    />
    <h6 class="mt-3">Movimento dos 15 dias antes e 15 dias após a data informada.</h6>
    <v-data-table
        class="w-auto"
        item-key="seqipo"
        dense
        :headers="headersItens"
        :items="item.movimentos"
        :items-per-page="10"
        :mobile-breakpoint="0"
    >

      <template v-slot:progress>
        <v-progress-linear
            absolute
            indeterminate
            color="green"
        />
      </template>

      <template v-slot:[`item.quantidade`]="{ item }">
        <span>{{ item.formatado.quantidade }}</span>
      </template>
      <template v-slot:[`item.criado_em`]="{ item }">
        <span>{{ item.formatado.criado_em }}</span>
      </template>
      <template v-slot:[`item.justificativa`]="{ item }">
        <span style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">{{ item.justificativa }}</span>
      </template>

    </v-data-table>
  </v-container>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
    },
  },

  computed: {
    headersItens () {
      return [
        { text: 'Usuario', value: 'usuario' },
        { text: 'Justificativa', value: 'justificativa' },
        { text: 'Quantidade', value: 'quantidade', align: 'right' },
        { text: 'Data movimento', value: 'criado_em', align: 'right' },
      ]
    },
  },
  methods: {
    converteUnidadeMedida (unidadeMedida, valor) {
      if (unidadeMedida === 'TN') {
        return parseFloat(valor) * 1000
      }

      return parseFloat(valor)
    },
  },
}
</script>
