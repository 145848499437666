<template>
  <v-card class="mb-5">
    <v-card-text class="py-0 px-0 px-md-4 py-md-4">
      <v-data-table
          ref="tabela"
          dense
          single-expand
          item-key="id"
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
          :expanded.sync="expanded"
          :headers="headers"
          :items="data"
          :loading="loading"
          :no-data-text="noData"
          :items-per-page.sync="perPage"
          :server-items-length="totalItems"
          :page.sync="page"
          :options.sync="options"
          :footer-props="$dataTableConfig.getFooterProps()"
          :loading-text="$dataTableConfig.getLoadText()"
          :mobile-breakpoint="0"
          @update:page="getData"
          @update:items-per-page="getData"
          @update:sort-by="getData"
          @update:sort-desc="getData"
        >
          <template v-slot:progress>
            <v-progress-linear
              absolute
              indeterminate
              color="green"
            />
          </template>
          <template v-slot:no-data>
            <v-spacer />
            <div>
              <v-row class="justify-center mt-1">
                <v-col class="col-lg-6">
                  <p>Não há dados para serem exibidos</p>
                </v-col>
              </v-row>
            </div>
          </template>
        <template v-slot:[`item.producao`]="{ item }">
          <span style="text-wrap: nowrap;">{{ item.producao }}</span>
        </template>
        <template v-slot:[`item.empresa`]="{ item }">
          <span style="text-wrap: nowrap;">{{ item.empresa }}</span>
        </template>
        <template v-slot:[`item.quantidade`]="{ item }">
          <span style="text-wrap: nowrap;">{{ item.formatado.quantidade }}</span>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <ViewComponents :item="item"></ViewComponents>
          </td>
        </template>

        </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>

import ApiService from '@/core/services/api.service'
import { can } from '@/core/services/storage.service'

import ViewComponents from './ViewComponent.vue'

export default {
  components: {
    ViewComponents,
  },
  props: {
    headers: {
      type: Object,
    },
    filtros: {
      type: Object,
    },
    reloadData: {
      type: Boolean,
      default: false,
    },
  },
  data: (instance) => ({
    // General
    loading: false,
    assumirAnaliseDialog: false,
    atualizarAnaliseDialog: false,
    confirmarContratacaoDialog: false,
    aprovarSolicitacaoDialog: false,
    reprovarSolicitacaoDialog: false,
    mostrarRejeitadaDialog: false,
    noData: 'Nenhuma informação encontrada',
    // Form Data
    data: [],
    // Expand Data Table
    expanded: [],
    perPage: instance.$dataTableConfig.getItemsPerPage(),
    page: 1,
    totalItems: 0,
    sortBy: 'created_at',
    sortDesc: true,
    options: {},
  }),

  watch: {
    filtros () {
      this.$router.push({ query: this.filtros, silent: true }).catch((e) => e)
      this.getData()
    },
    reloadData (val) {
      if (val) {
        this.getData()
        this.reloadData = false
      }

      this.$emit('update:reloadData', val)
    },
  },

  mounted () {
    this.getData()

    this.$refs.tabela.$el.querySelector('thead').addEventListener('wheel', (evt) => {
      evt.preventDefault()
      this.$refs.tabela.$el.querySelector('.v-data-table__wrapper').scrollLeft += evt.deltaY
    })
  },

  methods: {
    can,
    getData () {
      if (!this.filtros.data || !this.filtros.empresaId || !this.filtros.producaoId) {
        return
      }

      this.loading = true
      this.expanded = []
      /**
       * Configuração da página e ordem da tabela
       */
      const filtros = {
        pageSize: this.perPage,
        orderBy: this.sortBy,
        orderType: this.sortDesc ? 'DESC' : 'ASC',
        page: this.page,
        ...this.filtros,
      }

      ApiService
        .cancel('historico-estoque')
        .get('/agricola/estoque/consolidado', filtros)
        .then((res) => {
          this.data = res.data.data
          this.totalItems = res.data.meta.total

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    validDate (value) {
      if (value == null) {
        return '-'
      }

      return value
    },
  },
}
</script>

<style>
.bg-solicitada {
  background-color: #FDD01C;
  color: #000;
}
.bg-em-analise {
  background-color: #1F3853;
  color: #fff;
}
.bg-analisada {
  background-color: #0090D9;
  color: #fff;
}
.bg-aprovada {
  background-color: #0AA699;
  color: #fff;
}
.bg-reprovada {
  background-color: #E36968;
  color: #fff;
}
.bg-contratada {
  background-color: #0aa617;
  color: #fff;
}
.bg-fornecedor {
  background-color: #0aa617;
  color: #ffffff;
}
.bg-contratacao-direta {
  background-color: purple;
  color: #fff;
}
.label.label-lg.label-inline {
  width: 100%;
}
</style>
