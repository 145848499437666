import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-5"},[_c(VCardText,{staticClass:"py-0 px-0 px-md-4 py-md-4"},[_c(VDataTable,{ref:"tabela",attrs:{"dense":"","single-expand":"","item-key":"id","expanded":_vm.expanded,"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading,"no-data-text":_vm.noData,"items-per-page":_vm.perPage,"server-items-length":_vm.totalItems,"page":_vm.page,"options":_vm.options,"footer-props":_vm.$dataTableConfig.getFooterProps(),"loading-text":_vm.$dataTableConfig.getLoadText(),"mobile-breakpoint":0},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); },"update:expanded":function($event){_vm.expanded=$event},"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":[function($event){_vm.perPage=$event},_vm.getData],"update:page":[function($event){_vm.page=$event},_vm.getData],"update:options":function($event){_vm.options=$event},"update:sort-by":_vm.getData,"update:sort-desc":_vm.getData},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"no-data",fn:function(){return [_c(VSpacer),_c('div',[_c(VRow,{staticClass:"justify-center mt-1"},[_c(VCol,{staticClass:"col-lg-6"},[_c('p',[_vm._v("Não há dados para serem exibidos")])])],1)],1)]},proxy:true},{key:"item.producao",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-wrap":"nowrap"}},[_vm._v(_vm._s(item.producao))])]}},{key:"item.empresa",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-wrap":"nowrap"}},[_vm._v(_vm._s(item.empresa))])]}},{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-wrap":"nowrap"}},[_vm._v(_vm._s(item.formatado.quantidade))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('ViewComponents',{attrs:{"item":item}})],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }