<template>
  <div>
    <v-form ref="form">
      <FiltroMobile :has-change="change" @search="seachFilter">
        <v-col class="pb-0 col-12 col-md-6 col-lg-4">
          <EmpresasSelect
              v-model="empresaId"
              @change="change = true"
              obrigatorio
          />
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-4">
          <ProducoesSelect
              v-model="producaoId"
              :empresa="empresaId"
              :disabled="!empresaId"
              @change="change = true"
              obrigatorio
          />
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-4">
          <ProdutoSelect
              v-model="produtoId"
              :empresa="empresaId"
              :producao="producaoId"
              :disabled="!producaoId"
              @change="change = true"
          />
        </v-col>
        <v-col class="pb-0 col-12 col-md-2 col-lg-2">
          <DatePicker label="Data" v-model="data" @input="change = true"/>
        </v-col>
      </FiltroMobile>
    </v-form>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
    },
  },
  data: () => {
    return {
      change: false,
      empresaId: '',
      producaoId: '',
      produtoId: '',
      data: '',
    }
  },
  mounted () {
    this.empresaId = this.value?.empresaId
    this.producaoId = this.value?.producaoId
    this.produtoId = this.value?.produtoId
    this.data = this.value?.data
  },
  watch: {
    value (val) {
      this.empresaId = val?.empresaId
      this.producaoId = val?.producaoId
      this.produtoId = val?.produtoId
      this.data = val?.data
    },
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    seachFilter () {
      if (!this.$refs.form.validate()) {
        return false
      }

      this.model = {
        empresaId: this.empresaId,
        producaoId: this.producaoId,
        produtoId: this.produtoId,
        data: this.data,
      }

      this.change = false
    },
  },
}
</script>

<style scoped>

</style>
