import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('custom-snackbar',{ref:"snackbar"}),_c('h6',{staticClass:"mt-3"},[_vm._v("Movimento dos 15 dias antes e 15 dias após a data informada.")]),_c(VDataTable,{staticClass:"w-auto",attrs:{"item-key":"seqipo","dense":"","headers":_vm.headersItens,"items":_vm.item.movimentos,"items-per-page":10,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.formatado.quantidade))])]}},{key:"item.criado_em",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.formatado.criado_em))])]}},{key:"item.justificativa",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap"}},[_vm._v(_vm._s(item.justificativa))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }